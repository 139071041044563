import React, { ChangeEventHandler, FC, memo, useCallback, useEffect, useMemo } from "react";

import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import uniq from "lodash/uniq";

import { MasterSongList } from "$components/master-song-list";
import { SongItem } from "$components/setlist-editor/songItem";
import { useSetlistProvider } from "$providers/setlist-provider";
import { useSongProvider } from "$providers/song-provider";

export const SetlistEditor: FC = memo(() => {
  const { sets, moveItemInSet, currentSet, selectSetFromID } = useSetlistProvider();
  const { songs } = useSongProvider();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const onSetSelected = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    ({ currentTarget: { value } }) => {
      selectSetFromID(Number(value));
    },
    [selectSetFromID],
  );

  const handleDragEnd = useCallback(
    async (event) => {
      const { active, over } = event;

      if (!active?.id || !over?.id) {
        return;
      }

      if (active.id !== over.id) {
        await moveItemInSet({ songNumber: active.id, positionAtSongNumber: over.id });
      }
    },
    [moveItemInSet],
  );

  useEffect(() => {
    if (!currentSet && sets.length) selectSetFromID(sets[0].id);
  }, [currentSet, selectSetFromID, sets]);

  const songOrder = useMemo(() => uniq(currentSet?.songs.sort(({ sorting: sortA }, { sorting: sortB }) => sortB - sortA).map((item) => item.num)), [currentSet?.songs]);

  if (!sets || !sets.length) {
    return <div>No setlists exist in the system.</div>;
  }

  return (
    <div>
      <MasterSongList />

      <select onChange={onSetSelected} defaultValue={currentSet?.id}>
        {sets.map((set) => (
          <option key={set.id} value={set.id}>
            {set.name}
          </option>
        ))}
      </select>
      {currentSet && (
        <section>
          <h2>{currentSet.name}</h2>
          <p>{currentSet.desc}</p>
          <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={songOrder} strategy={verticalListSortingStrategy}>
              <div>
                {songOrder.map((songNumber) => (
                  <SongItem key={songNumber} song={songs[songNumber]} />
                ))}
              </div>
            </SortableContext>{" "}
          </DndContext>
        </section>
      )}
    </div>
  );
});

SetlistEditor.displayName = "SetlistEditor";
