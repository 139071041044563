import React, { FC } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { IconButton } from "$components/icon-button";
import { SongItemWrapper } from "$components/setlist-editor/styledElements";
import { useSetlistProvider } from "$providers/setlist-provider";
import { Song } from "$types/song";

interface SongItemProps {
  song: Song;
}

export const SongItem: FC<SongItemProps> = ({ song = { title: "-", num: 0 } }) => {
  const { removeSongFromSet } = useSetlistProvider();
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: song.num });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <SongItemWrapper ref={setNodeRef} style={style} {...attributes} {...listeners} isDragging={isDragging}>
      <IconButton design="angry" type="button" onClick={() => removeSongFromSet(song.num)}>
        -
      </IconButton>
      <div>
        {song.title} ({song.num})
      </div>
    </SongItemWrapper>
  );
};
