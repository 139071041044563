import React, { createContext, FC, memo, ReactNode, useCallback, useContext, useMemo, useState } from "react";

import { arrayMove } from "@dnd-kit/sortable";
import useSWR from "swr";

import { API_URL } from "$config/constants";
import { Set } from "$types/set";
import { Song } from "$types/song";

type MoveItemInSetArgs = {
  songNumber: number;
  positionAtSongNumber: number;
};

export type SetlistProviderType = {
  loading?: boolean;
  sets: Set[];

  moveItemInSet: (data: MoveItemInSetArgs) => Promise<boolean>;
  addSongToSet: (songNumber: Song["num"]) => Promise<boolean>;
  removeSongFromSet: (songNumber: Song["num"]) => Promise<boolean>;

  currentSet: Set | undefined;
  selectSetFromID: (id: Set["id"]) => void;
};

type SetlistProviderWrapperProps = {
  children: ReactNode;
};

export const SETLIST_PROVIDER_CONTEXT = createContext<SetlistProviderType>({
  sets: [],
  moveItemInSet: () => Promise.resolve(null),
  addSongToSet: () => Promise.resolve(null),
  removeSongFromSet: () => Promise.resolve(null),
  selectSetFromID: () => undefined,
  currentSet: undefined,
});

export const useSetlistProvider = () => useContext(SETLIST_PROVIDER_CONTEXT);

export const SetlistProviderWrapper: FC<SetlistProviderWrapperProps> = memo(({ children }) => {
  const { data: sets = [], isValidating: loading, mutate } = useSWR<Set[]>(`${API_URL}getSetlists`);

  const [currentSet, setCurrentSet] = useState<Set | undefined>();
  const [operationRunning, setOperationRunning] = useState(false);

  const selectSetFromID = useCallback<SetlistProviderType["selectSetFromID"]>(
    (targetID) => {
      const setToUse = sets.find(({ id }) => id === targetID);
      if (setToUse) setCurrentSet(setToUse);
    },
    [sets],
  );

  const updateSet = useCallback(
    async (set: Set) => {
      await fetch(`${API_URL}updateSetlist`, { method: "POST", body: JSON.stringify(set), headers: { ContentType: "application/json" } });
      await mutate([...sets.filter((item) => item.id !== set.id), set]);
    },
    [mutate, sets],
  );

  const addSongToSet = useCallback<SetlistProviderType["addSongToSet"]>(
    async (songNumber) => {
      if (!currentSet) return false;

      const targetSet = sets.find(({ id }) => id === currentSet?.id);
      if (targetSet) {
        const { songs } = targetSet;
        const updatedSetData: Set = { ...targetSet, songs: [...songs, { num: songNumber, sorting: songs.length + 1 }] };
        await updateSet(updatedSetData);
      }

      return true;
    },
    [currentSet, sets, updateSet],
  );

  const removeSongFromSet = useCallback<SetlistProviderType["removeSongFromSet"]>(
    async (songNumber) => {
      if (!currentSet) return false;

      const targetSet = sets.find(({ id }) => id === currentSet?.id);
      if (targetSet) {
        const { songs } = targetSet;
        const updatedSetData: Set = { ...targetSet, songs: songs.filter((item) => item.num !== songNumber) };
        await updateSet(updatedSetData);
      }

      return true;
    },
    [currentSet, sets, updateSet],
  );

  const moveItemInSet = useCallback<SetlistProviderType["moveItemInSet"]>(
    async ({ positionAtSongNumber, songNumber }) => {
      const targetSet = sets.find(({ id }) => id === currentSet?.id);
      if (targetSet) {
        setOperationRunning(true);
        const { songs } = targetSet;
        const oldIndex = songs.findIndex((song) => song.num === songNumber);
        const newIndex = songs.findIndex((song) => song.num === positionAtSongNumber);
        const updatedSongList = arrayMove(songs, oldIndex, newIndex).map((song, sorting) => ({ ...song, sorting: sorting + 1 }));
        const updatedSetData = { ...targetSet, songs: updatedSongList };
        await updateSet(updatedSetData);
        setOperationRunning(false);
      } else {
        return false;
      }

      return true;
    },
    [currentSet?.id, sets, updateSet],
  );

  const memoedValue: SetlistProviderType = useMemo(
    () => ({ sets, loading: operationRunning || loading, moveItemInSet, currentSet, selectSetFromID, addSongToSet, removeSongFromSet }),
    [sets, operationRunning, loading, moveItemInSet, currentSet, selectSetFromID, addSongToSet, removeSongFromSet],
  );

  return <SETLIST_PROVIDER_CONTEXT.Provider value={memoedValue}>{children}</SETLIST_PROVIDER_CONTEXT.Provider>;
});

SetlistProviderWrapper.displayName = "SetlistProviderWrapper";
