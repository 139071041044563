import styled, { css } from "styled-components";

import { resetButton } from "$styles/mixins";

export const IconButton = styled.button<{ design?: "angry" | "happy" | "boring" }>(
  ({ design = "boring", theme }) => css`
    ${resetButton};
    width: 1.4em;
    height: 1.4em;
    font-size: 24px;
    line-height: 1em;
    overflow: hidden;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #ccc;
    background: #fff;
    text-align: center;
    font-weight: bolder;

    ${design === "boring" &&
    css`
      background: ${theme.palette.grey800};
      border-color: ${theme.palette.grey400};
      color: ${theme.palette.grey400};
    `}

    ${design === "angry" &&
    css`
      background: #8d2323;
      border-color: #650202;
      color: white;

      &:hover,
      &:active {
        background: #ad2c2c;
      }
    `}
  `,
);
