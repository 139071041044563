import { createGlobalStyle, css } from "styled-components";
import { reset } from "styled-reset";

export const GlobalStyles = createGlobalStyle(
  ({ theme }) => css`
    ${reset}
    html, body {
      font-size: 14px;
    }

    body {
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      line-height: 1.5;
      color: ${theme.palette.greenDarker};
    }

    *,
    *:after,
    *:before {
      box-sizing: border-box;
    }

    svg,
    img {
      display: inline-block;
      vertical-align: top;
    }

    svg {
      fill: currentColor;
    }

    a {
      color: inherit;

      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
  `,
);
