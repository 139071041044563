import React from "react";

import { ThemeProvider } from "styled-components";
import { SWRConfig } from "swr/_internal";

import { SetlistEditor } from "$components/setlist-editor";
import { SetlistProviderWrapper } from "$providers/setlist-provider";
import { SongProviderWrapper } from "$providers/song-provider";
import { DevotionTheme } from "$providers/theme-provider";

import { GlobalStyles } from "./styles/globalTheme";

function App() {
  return (
    <ThemeProvider theme={DevotionTheme}>
      <GlobalStyles />
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          fetcher: (resource, init) => fetch(resource, init).then((res) => res.json()),
        }}
      >
        <SongProviderWrapper>
          <SetlistProviderWrapper>
            <SetlistEditor />
          </SetlistProviderWrapper>
        </SongProviderWrapper>
      </SWRConfig>
    </ThemeProvider>
  );
}

export default App;
