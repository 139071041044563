import styled, { css } from "styled-components";

export const SongItemWrapper = styled.div<{ isDragging?: boolean }>(
  ({ theme, isDragging }) => css`
    display: flex;
    gap: 10px;
    padding: 10px 5px;
    align-items: center;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid ${theme.palette.grey700};
    background: #fff;

    &:nth-child(odd) {
      background: ${theme.palette.grey900};
    }

    ${isDragging &&
    css`
      z-index: 5;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.45);
    `}
  `,
);
