import React, { FC, memo, useMemo, useState } from "react";

import { IconButton } from "$components/icon-button";
import { BlinderButton, CloseButton, ListWrapper, ModalWrapper, SongTitle } from "$components/master-song-list/styledElements";
import { useSetlistProvider } from "$providers/setlist-provider";
import { useSongProvider } from "$providers/song-provider";

export const MasterSongList: FC = memo(() => {
  const [showList, setShowList] = useState(false);
  const { songs } = useSongProvider();
  const { addSongToSet, removeSongFromSet, currentSet: { songs: songsInSet = [] } = {} } = useSetlistProvider();
  const parsedSongsInSet = useMemo(() => songsInSet.map((item) => item.num), [songsInSet]);

  if (!songs || !Object.keys(songs).length) {
    return <div>No songs in the list yet.</div>;
  }

  return (
    <section>
      <button type="button" onClick={() => setShowList(true)}>
        Show master song list
      </button>

      {showList && (
        <>
          <BlinderButton type="button" onClick={() => setShowList(false)} title="Close" aria-label="Close list" />
          <ModalWrapper>
            <CloseButton type="button" onClick={() => setShowList(false)} title="Close">
              X
            </CloseButton>
            <ListWrapper>
              {Object.values(songs).map(({ num, title }) => {
                const active = parsedSongsInSet.includes(num);
                return (
                  <li key={num}>
                    {active ? (
                      <IconButton design="angry" type="button" onClick={() => removeSongFromSet(num)}>
                        -
                      </IconButton>
                    ) : (
                      <IconButton design="happy" type="button" onClick={() => addSongToSet(num)}>
                        +
                      </IconButton>
                    )}
                    <SongTitle active={active}>
                      {title} ({num})
                    </SongTitle>
                  </li>
                );
              })}
            </ListWrapper>
          </ModalWrapper>
        </>
      )}
    </section>
  );
});

MasterSongList.displayName = "MasterSongList";
