import { css } from "styled-components";

export const onPhablet = `@media (min-width: 480px)`;
export const onTablet = `@media (min-width: 768px)`;
export const onDesktop = `@media (min-width: 1024px)`;

export const resetButton = css`
  border: none;
  background: none;
  appearance: none;
  padding: 0;
  color: inherit;
  text-decoration: none;
  text-shadow: none;
  font-style: normal;
  cursor: pointer;
`;
