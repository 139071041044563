import styled, { css } from "styled-components";

import { IconButton } from "$components/icon-button";
import { onTablet, resetButton } from "$styles/mixins";

export const BlinderButton = styled.button(
  () => css`
    ${resetButton};
    position: fixed;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  `,
);

export const ModalWrapper = styled.aside(
  () => css`
    position: fixed;
    z-index: 11;
    max-height: 80vh;
    max-width: 600px;
    left: 50%;
    top: 50%;
    width: 90%;
    height: 90%;
    background: #fff;
    padding: 10px;
    transform: translate(-50%, -50%);

    ${onTablet} {
      padding: 20px;
    }
  `,
);

export const ListWrapper = styled.ol(
  ({ theme }) => css`
    overflow: auto;
    max-height: 100%;

    ${IconButton} {
      margin-right: 1em;
    }

    li {
      border-bottom: 1px solid ${theme.palette.grey700};
      padding: 5px 0 5px 5px;
      background: white;

      &:nth-child(odd) {
        background: ${theme.palette.grey900};
      }
    }
  `,
);

export const CloseButton = styled(IconButton)(
  () => css`
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
  `,
);

export const SongTitle = styled.span<{ active?: boolean }>(
  ({ active }) =>
    css`
      ${active &&
      css`
        font-weight: bold;
      `}
    `,
);
