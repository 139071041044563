import React, { createContext, FC, memo, ReactNode, useContext, useMemo } from "react";

import useSWR from "swr";

import { API_URL } from "$config/constants";
import { Song } from "$types/song";

export type SongProviderType = {
  songs: Record<number, Song>;
  loading?: boolean;
};

export const SONG_PROVIDER_CONTEXT = createContext<SongProviderType>({
  songs: [],
});

export const useSongProvider = () => useContext(SONG_PROVIDER_CONTEXT);

export const SongProviderWrapper: FC<{ children: ReactNode }> = memo(({ children }) => {
  const { data: songs = {}, isValidating: loading } = useSWR(`${API_URL}getSongList`, {
    fetcher: (resource, init) =>
      fetch(resource, init)
        .then((res) => res.json())
        .then((data) => {
          const output = {};
          data.forEach((song: Song) => {
            output[song.num] = song;
          });
          return output;
        }),
  });

  const memoedValue = useMemo(() => ({ songs, loading }), [loading, songs]);

  return <SONG_PROVIDER_CONTEXT.Provider value={memoedValue}>{children}</SONG_PROVIDER_CONTEXT.Provider>;
});

SongProviderWrapper.displayName = "SongProviderWrapper";
