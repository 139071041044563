export type DevotionThemeType = {
  palette: {
    red: string;
    grey900: string;
    grey800: string;
    grey700: string;
    grey600: string;
    grey400: string;
  };
};

export const DevotionTheme: DevotionThemeType = {
  palette: {
    red: "#ff0000",
    grey900: "#fafafa",
    grey800: "#eee",
    grey700: "#ccc",
    grey600: "#aaa",
    grey400: "#777",
  },
};
